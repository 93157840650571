<template>
    <BaseInput
        v-model="data"
        type="date"
        :label="label"
        :name="label"
        :rules="{required: cardRequired}"
        :required="cardRequired" />
</template>
<script setup>
const props = defineProps({
    modelValue: {
        required: true,
        type: String
    },
    cardRequired: {
        required: true,
        type: Boolean
    },
    label: {
        type: String,
        default: 'Geburtstag'
    }
})

const emit = defineEmits(['update:modelValue'])

const data = computed({
    get: () => props.modelValue,
    set: (value) => {
        emit('update:modelValue', value)
    }
})
</script>

<style>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
</style>