<template>
    <div
        v-if="eventData"
        class="border rounded border-gray-300 bg-gray-50 py-4 my-2 divide-y">
        <div class="px-4">
            <div class="px-4 sm:px-0 flex justify-between">
                <h3 class="text-base font-semibold leading-7 text-gray-900">
                    Veranstaltung
                </h3>
                <BaseButton
                    type="secondary"
                    :disabled="formProcessStore.formProcessState !== 'editable'"
                    @click.prevent="editEvent">
                    {{ $t('formProcesses.event.editEvent') }}
                </BaseButton>
            </div>
            <div class="mt-4">
                <dl>
                    <div
                        v-if="!hideName"
                        class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">
                            {{ labelName }}
                        </dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {{ eventData.name }}
                            <BaseInput
                                v-model="eventData.name"
                                :rules="{required: eventCardSetting === 'required'}"
                                :name="$t('formProcesses.event.name')"
                                type="hidden" />
                        </dd>
                    </div>
                    <div
                        v-if="!hideLocation"
                        class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">
                            {{ labelLocation }}
                        </dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {{ eventData.street }} {{ eventData.house_number }},<br>
                            {{ eventData.zipcode }} {{ eventData.city }}
                            <BaseInput
                                v-model="eventData.street"
                                :rules="{required: eventCardSetting === 'required'}"
                                :name="$t('formProcesses.event.street')"
                                type="hidden" />
                            <BaseInput
                                v-model="eventData.house_number"
                                :rules="{required: eventCardSetting === 'required'}"
                                :name="$t('formProcesses.event.house_number')"
                                type="hidden" />
                            <BaseInput
                                v-model="eventData.zipcode"
                                :rules="{required: eventCardSetting === 'required'}"
                                :name="$t('formProcesses.event.zipcode')"
                                type="hidden" />
                            <BaseInput
                                v-model="eventData.city"
                                :rules="{required: eventCardSetting === 'required'}"
                                :name="$t('formProcesses.event.city')"
                                type="hidden" />
                        </dd>
                    </div>
                    <div
                        v-if="!hideDate"
                        class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">
                            {{ labelDate }}
                        </dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <BaseDate :date="eventData.start_date" /> -
                            <BaseDate :date="eventData.end_date" />,
                            {{ $t('formProcesses.event.durationDays', eventData.duration) }}
                            <BaseInput
                                v-model="eventData.start_date"
                                :rules="{required: eventCardSetting === 'required'}"
                                :name="$t('formProcesses.event.startDate')"
                                type="hidden" />
                            <BaseInput
                                v-model="eventData.end_date"
                                :rules="{required: eventCardSetting === 'required'}"
                                :name="$t('formProcesses.event.endDate')"
                                type="hidden" />
                        </dd>
                    </div>
                </dl>
            </div>
        </div>

        <EventPersons
            v-if="eventPersonsCardSetting !== 'hidden'"
            id="eventPersons"
            @cancel="cancel" />

        <BaseSlideOver
            v-model="showEditEvent"
            title="Veranstaltung">
            <div @keydown.enter="$event ? $event.preventDefault() : null">
                <VForm
                    v-slot="validation"
                    @submit="onSubmit">
                    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                        <EventEdit
                            v-if="showEditEvent"
                            v-model="eventToEdit"
                            :hide-name="hideName"
                            :hide-location="hideLocation"
                            :hide-date="hideDate" />
                    </div>

                    <BaseSubmitButtons
                        :submit-label="$t('formProcesses.event.saveEvent')"
                        :loading="loading"
                        :validation="validation"
                        @cancel="cancel" />
                </VForm>
            </div>
        </BaseSlideOver>
    </div>
</template>

<script setup>
import { useFormProcessStore } from "@/store/formProcess";
import { useNotification } from "@/composables/useNotification";
import EventEdit from "@/components/pages/formProcess/EventEdit.vue";

const formProcessStore = useFormProcessStore()
const notification = useNotification()
const {t: $t} = useI18n()

const components = {
    EventEdit
}

const props = defineProps({
    labelName: {
        type: String,
        default: 'Name',
    },
    labelLocation: {
        type: String,
        default: 'Ort',
    },
    labelDate: {
        type: String,
        default: 'Zeitraum',
    },
    hideName: {
        type: Boolean,
        default: false,
    },
    hideLocation: {
        type: Boolean,
        default: false,
    },
    hideDate: {
        type: Boolean,
        default: false,
    },
})

const eventData = computed(() => formProcessStore.formProcessData.process.event)
const eventCardSetting = computed(() => formProcessStore.formProcessData.form.event_card)
const eventPersonsCardSetting = computed(() => formProcessStore.formProcessData.form.persons_card)

const loading = ref(false)
const eventToEdit = ref(null)
const showEditEvent = computed({
    get: () => !!eventToEdit.value,
    set: (value) => {
        if (!value) {
            eventToEdit.value = null
        }
    },
})

const editEvent = () => {
    eventToEdit.value = eventData.value
}

function onSubmit() {
    loading.value = true
    $lara
        .put(useNuxtApp().$apiRoute('spa.processes.events.update', {event: eventData.value.id}), {
            body: {...eventData.value},
            formDataRequest: true
        })
        .then((response) => {
            notification.success($t('formProcesses.event.successfullySaved'))
            formProcessStore.formProcessData.process.event = response.data

            loading.value = false
            eventToEdit.value = null
        })
        .catch(() => {
            loading.value = false
            notification.error($t('formProcesses.event.couldNotBeSaved'))
        })
}

function cancel($event) {
    if ($event) $event.preventDefault()
    loading.value = true
    $lara
        .get(useNuxtApp().$apiRoute('spa.processes.events.show', {event: eventData.value.id}))
        .then((response) => {
            formProcessStore.formProcessData.process.event = response.data

            loading.value = false
            eventToEdit.value = null
        })
        .catch(() => {
            loading.value = false
        })
}

</script>

<style scoped>

</style>