<template>
    <div>
        <div class="p-4 sm:p-6">
            <div class="font-bold">
                Bankverbindung
            </div>
            <div class="flex justify-between items-center">
                <p class="mt-2 text-sm">
                    {{ formProcessStore.formProcessData?.bankAccount?.designation }}<br>
                    <small>
                        {{ formProcessStore.formProcessData?.bankAccount?.iban }}<br>
                        {{ formProcessStore.formProcessData?.bankAccount?.account_holder }}
                    </small>
                </p>
                <BaseButton
                    v-if="!disabled"
                    type="secondary"
                    @click.prevent="showEditBankAccount = true">
                    <Icon name="heroicons:pencil-20-solid" />
                </BaseButton>
            </div>
        </div>

        <BaseSlideOver
            v-model="showEditBankAccount"
            size="md"
            title="Bankverbindung">
            <div @keydown.enter="$event ? $event.preventDefault() : null">
                <VForm
                    v-slot="validation"
                    @submit="onSubmit">
                    <div class="grid grid-cols-1 gap-y-8">
                        <fieldset>
                            <div class="space-y-5">
                                <div
                                    v-for="bankAccount in bankAccountStore.allBankAccounts"
                                    :key="bankAccount.id"
                                    class="relative flex items-start">
                                    <div class="flex h-6 items-center">
                                        <input
                                            :id="bankAccount.id"
                                            v-model="selectedBankAccount"
                                            :value="bankAccount.id"
                                            :aria-describedby="`${bankAccount.id}-description`"
                                            name="bankAccount"
                                            type="radio"
                                            :checked="bankAccount.id === selectedBankAccount"
                                            class="h-4 w-4 border-gray-300 text-primary focus:ring-primary">
                                    </div>
                                    <div class="ml-3 text-sm leading-6">
                                        <label
                                            :for="bankAccount.id"
                                            class="font-medium text-gray-900">
                                            {{ bankAccount.designation }}
                                            <span
                                                v-if="bankAccount.default"
                                                class="text-primary text-xs pl-1">Standard</span>
                                        </label>
                                        <p
                                            :id="`${bankAccount.id}-description`"
                                            class="text-gray-500">
                                            {{ bankAccount.iban }}<br>
                                            {{ bankAccount.account_holder }}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="relative flex items-start">
                                    <div class="flex h-6 items-center">
                                        <input
                                            id="newBankAccount"
                                            v-model="selectedBankAccount"
                                            value="newBankAccount"
                                            aria-describedby="newBankAccount-description"
                                            name="bankAccount"
                                            type="radio"
                                            :checked="'newBankAccount' === selectedBankAccount"
                                            class="h-4 w-4 border-gray-300 text-primary focus:ring-primary">
                                    </div>
                                    <div class="flex-grow ml-3 text-sm leading-6">
                                        Neue Bankverbindung:

                                        <BankAccountFormGeneral
                                            v-model="newBankAccount"
                                            :required="selectedBankAccount === 'newBankAccount'"
                                            wrapper-classes="grid gap-y-4"
                                            hide-default-switch />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <BaseSubmitButtons
                        :submit-label="$t('organisations.bankAccounts.selectBankAccount')"
                        :loading="bankAccountStore.loadingSubmit"
                        :validation="validation"
                        @cancel="showEditBankAccount = false" />
                </VForm>
            </div>
        </BaseSlideOver>
    </div>
</template>

<script setup>
import {useFormProcessStore} from "@/store/formProcess";
import {useBankAccountStore} from "@/store/bankAccount";

const formProcessStore = useFormProcessStore()
const bankAccountStore = useBankAccountStore()
const {t: $t} = useI18n()

const showEditBankAccount = ref(false)
const disabled = computed(() => {
    return (formProcessStore.formProcessState !== 'editable')
})

const newBankAccount = ref({
    designation: '',
    iban: '',
    account_holder: '',
    default: false,
})

const selectedBankAccount = ref(null)

onMounted(() => {
    selectedBankAccount.value = formProcessStore.formProcessData?.bankAccount?.id
    bankAccountStore.fetchAllBankAccounts()
})

async function onSubmit() {
    if (selectedBankAccount.value === 'newBankAccount') {
        bankAccountStore.bankAccountToEdit = newBankAccount.value

        await bankAccountStore.storeBankAccountData(formProcessStore.formProcessData.organisation_id)

        selectedBankAccount.value = bankAccountStore.lastStoredBankAccount.id
        formProcessStore.formProcessData.bank_account_id = selectedBankAccount.value
        formProcessStore.formProcessData.bankAccount = bankAccountStore.lastStoredBankAccount

        newBankAccount.value = {
            designation: '',
            iban: '',
            account_holder: '',
            default: false,
        }

    } else {
        formProcessStore.formProcessData.bank_account_id = selectedBankAccount.value
        formProcessStore.formProcessData.bankAccount = bankAccountStore.allBankAccounts.find(bankAccount => bankAccount.id === selectedBankAccount.value)
    }

    showEditBankAccount.value = false
}

watch(() => newBankAccount.value.designation, () => selectedBankAccount.value = 'newBankAccount')
watch(() => newBankAccount.value.iban, () => selectedBankAccount.value = 'newBankAccount')
watch(() => newBankAccount.value.account_holder, () => selectedBankAccount.value = 'newBankAccount')
</script>