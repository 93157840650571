import {defineStore} from 'pinia'
import {useNotification} from '@/composables/useNotification'
import {useNuxtApp} from 'nuxt/app'
import {ref} from 'vue'
import {$lara, $larafetch} from '@/utils/$larafetch'

export const useEventPersonStore = defineStore('eventPerson', () => {
    const allEventPersons = ref([])
    const eventPersonsLoaded = ref(false)
    const eventPersonToEdit = ref(null)
    const eventPersonToDelete = ref(null)
    const eventPersonDoesNotExist = ref(false)
    const loadingSubmit = ref(false)
    const loadingDelete = ref(false)

    function storeAllEventPersons(response) {
        allEventPersons.value = response.data
        eventPersonsLoaded.value = true
        eventPersonDoesNotExist.value = false
    }

    function handleRequestError(error) {
        const notification = useNotification()
        const app = useNuxtApp()
        eventPersonsLoaded.value = true
        loadingSubmit.value = false
        if (error && error.response && error.response.status === 422) {
            notification.error(app.$i18n.t('invalidRequestData') + '\n' + error.response._data.message)
        } else if (error && error.response && error.response.status === 404) {
            eventPersonDoesNotExist.value = true
            notification.error(app.$i18n.t('modelDoesNotExist'))
        }
        throw error
    }

    function fetchAllEventPersons(eventId) {
        eventPersonsLoaded.value = false
        $larafetch(useNuxtApp().$apiRoute('spa.processes.eventPersons.index'), {
            params: {
                event_id: eventId
            }
        })
            .then(storeAllEventPersons)
            .catch(handleRequestError)
    }

    async function storeEventPersonData(eventId) {
        loadingSubmit.value = true

        await $lara.post(useNuxtApp().$apiRoute('spa.processes.eventPersons.store'),
            {
                body: {
                    ...eventPersonToEdit.value,
                    event_id: eventId,
                }
            }).then(() => {
                const notification = useNotification()
                const app = useNuxtApp()
                notification.success(app.$i18n.t('formProcesses.eventPersons.successfullySaved'))

                loadingSubmit.value = false

                fetchAllEventPersons(eventId)
            })
            .catch(handleRequestError)
    }

    async function updateEventPersonData(eventId) {
        await $lara.put(useNuxtApp().$apiRoute('spa.processes.eventPersons.update', {eventPerson: eventPersonToEdit.value.id}), {
            body: eventPersonToEdit.value
        }).then(() => {
            const notification = useNotification()
            const app = useNuxtApp()
            notification.success(app.$i18n.t('formProcesses.eventPersons.successfullySaved'))

            loadingSubmit.value = false
            eventPersonToEdit.value = null

            fetchAllEventPersons(eventId)
        }).catch(handleRequestError)
    }

    async function deleteEventPersonData(eventId) {
        loadingDelete.value = true

        $lara.delete(useNuxtApp().$apiRoute('spa.processes.eventPersons.destroy', {eventPerson: eventPersonToDelete.value.id})
        ).then(() => {
            const notification = useNotification()
            const app = useNuxtApp()
            notification.success(app.$i18n.t('formProcesses.eventPersons.successfullyDeleted'))

            eventPersonToEdit.value = null
            loadingDelete.value = false

            fetchAllEventPersons(eventId)
        }).catch(handleRequestError)
    }

    function resetData() {
        allEventPersons.value = null
        eventPersonsLoaded.value = false
        eventPersonToEdit.value = null
        eventPersonToDelete.value = null
        eventPersonDoesNotExist.value = false
        loadingSubmit.value = false
        loadingDelete.value = false
    }

    return {
        allEventPersons,
        eventPersonsLoaded,
        eventPersonToEdit,
        eventPersonToDelete,
        eventPersonDoesNotExist,
        loadingSubmit,
        loadingDelete,
        fetchAllEventPersons,
        storeEventPersonData,
        updateEventPersonData,
        deleteEventPersonData,
        resetData,
    }
})
