<template>
    <div
        class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <div class="sm:col-span-6">
            <p class="pointer-events-none text-sm text-neutral-700 dark:text-gray-300">
                <span class="font-semibold me-2">Jahr: </span>
                <span v-if="eventData?.year_id">{{ eventData.year_id }}</span>
                <span v-else>Wird automatisch generiert</span>
            </p>
        </div>
        <div
            v-if="!hideName"
            class="sm:col-span-6">
            <BaseInput
                v-model="eventData.name"
                :label="$t('formProcesses.event.name')"
                :placeholder="$t('formProcesses.event.name')"
                :rules="{max: 255}" />
        </div>
        <div
            v-if="!hideLocation"
            class="sm:col-span-6">
            <AddressSelect
                v-model="eventData" />
        </div>
        <div
            v-if="!hideDate"
            class="md:col-span-2">
            <BaseDatePicker
                v-model="startDate"
                :label="$t('formProcesses.event.startDate')"
                mode="date" />
        </div>
        <div
            v-if="!hideDate"
            class="md:col-span-2">
            <BaseDatePicker
                v-model="endDate"
                :label="$t('formProcesses.event.endDate')"
                mode="date" />
        </div>
        <div
            v-if="!hideDate"
            class="sm:col-span-2">
            <BaseInput
                v-model="eventData.duration"
                :label="$t('formProcesses.event.duration')"
                :placeholder="$t('formProcesses.event.duration')"
                :rules="{max: 100}" />
        </div>
    </div>
</template>

<script setup>
import { useFormProcessStore } from "@/store/formProcess";
import BaseDatePicker from "@/components/global/Inputs/BaseDatePicker";

const props = defineProps({
    hideName: {
        type: Boolean,
        default: false,
    },
    hideLocation: {
        type: Boolean,
        default: false,
    },
    hideDate: {
        type: Boolean,
        default: false,
    },
})

const formProcessStore = useFormProcessStore()
const dayjs = useDayjs()

const eventData = computed(() => formProcessStore.formProcessData.process.event)

const startDate = computed({
    get: () => eventData.value.start_date,
    set: (value) => eventData.value.start_date = dayjs(value).format('YYYY-MM-DD')
})
const endDate = computed({
    get: () => eventData.value.end_date,
    set: (value) => eventData.value.end_date = dayjs(value).format('YYYY-MM-DD')
})

watchEffect(() => {
    const duration = dayjs(eventData.value.end_date).diff(dayjs(eventData.value.start_date), 'day')
    eventData.value.duration = (isNaN(duration)) ? null : duration + 1
})
</script>

<style scoped>

</style>