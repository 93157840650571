<template>
    <div class="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6 md:col-span-2">
        <div class="sm:col-span-3">
            <BaseInput
                v-model="data.gender"
                :label="$t('formProcesses.eventPersons.gender')"
                :name="$t('formProcesses.eventPersons.gender')"
                :rules="{required: cardRequired}"
                :required="cardRequired">
                <BaseSelect v-model="data.gender">
                    <option
                        v-for="eventPersonsGender in availableGenders"
                        :key="eventPersonsGender"
                        :value="eventPersonsGender">
                        {{ $t('formProcesses.eventPersons.genders.' + eventPersonsGender) }}
                    </option>
                </BaseSelect>
            </BaseInput>
        </div>
        <div class="sm:col-span-3" />
        <div class="sm:col-span-3">
            <BaseInput
                v-model="data.firstname"
                :label="$t('formProcesses.eventPersons.firstname')"
                :name="$t('formProcesses.eventPersons.firstname')"
                :rules="{required: cardRequired}"
                :required="cardRequired" />
        </div>
        <div class="sm:col-span-3">
            <BaseInput
                v-model="data.lastname"
                :label="$t('formProcesses.eventPersons.lastname')"
                :name="$t('formProcesses.eventPersons.lastname')"
                :rules="{required: cardRequired}"
                :required="cardRequired" />
        </div>
        <div class="sm:col-span-3">
            <BirthdayInput
                v-model="data.birthday"
                :card-required="cardRequired" />
        </div>
        <div class="sm:col-span-3">
            <BaseInput
                v-model="data.function"
                :label="$t('formProcesses.eventPersons.function')"
                :name="$t('formProcesses.eventPersons.function')"
                :rules="{required: cardRequired}"
                :required="cardRequired">
                <BaseSelect v-model="data.function">
                    <option
                        v-for="eventPersonFunction in availableFunctions"
                        :key="eventPersonFunction"
                        :value="eventPersonFunction">
                        {{ $t('formProcesses.eventPersons.functions.' + eventPersonFunction) }}
                    </option>
                </BaseSelect>
            </BaseInput>
        </div>

        <div class="sm:col-span-6 my-10">
            <AddressSelect
                :model-value="data"
                input-identifier-prop="id"
                grid-gaps="gap-x-6 gap-y-2"
                :required="cardRequired"
                :nullable="!cardRequired"
                @update:model-value="addressData => data = addressData" />
        </div>

        <div class="sm:col-span-3">
            <BaseInput
                v-model="data.participation_begin"
                :label="$t('formProcesses.eventPersons.participation_begin')"
                :name="$t('formProcesses.eventPersons.participation_begin')"
                :rules="{required: cardRequired}"
                :required="cardRequired">
                <BaseDatePicker
                    v-model="data.participation_begin"
                    :label="$t('formProcesses.eventPersons.participation_begin')"
                    :show-label="false"
                    mode="date"
                    :is-required="cardRequired"
                    :clearable="!cardRequired"
                    @update:model-value="data.participation_begin = (($event) ? dayjs($event).format('YYYY-MM-DD') : $event)" />
            </BaseInput>
        </div>
        <div class="sm:col-span-3">
            <BaseInput
                v-model="data.participation_end"
                :label="$t('formProcesses.eventPersons.participation_end')"
                :name="$t('formProcesses.eventPersons.participation_end')"
                :rules="{required: cardRequired}"
                :required="cardRequired">
                <BaseDatePicker
                    :model-value="data.participation_end"
                    :label="$t('formProcesses.eventPersons.participation_end')"
                    :show-label="false"
                    mode="date"
                    anchor-point="right"
                    :is-required="cardRequired"
                    :clearable="!cardRequired"
                    @update:model-value="data.participation_end = (($event) ? dayjs($event).format('YYYY-MM-DD') : $event)" />
            </BaseInput>
        </div>
        <div class="sm:col-span-3">
            <BaseInput
                v-model="data.participation_duration"
                :label="$t('formProcesses.eventPersons.participation_duration')"
                :name="$t('formProcesses.eventPersons.participation_duration')"
                :rules="{required: cardRequired}"
                :required="cardRequired" />
        </div>
    </div>
</template>

<script setup>
import BaseDatePicker from "@/components/global/Inputs/BaseDatePicker.vue";

const dayjs = useDayjs()

const props = defineProps({
    modelValue: {
        required: true,
        type: Object,
        description: 'The model holding the address'
    },
    cardRequired: {
        required: false,
        type: Boolean,
        default: false,
        description: 'Whether all inputs of the card are required or not'
    }
})

const availableFunctions = [
    'participant', 'staff', 'leader',
]

const availableGenders = [
    'male', 'female', 'diverse', 'other'
]

const emit = defineEmits(['update:modelValue', 'deleteEventPerson'])

const data = computed({
    get: () => props.modelValue,
    set: (value) => {
        emit('update:modelValue', value)
    }
})
</script>