<template>
    <div
        v-if="eventData"
        class="mt-10">
        <div class="px-4 sm:px-0 flex justify-between mb-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">
                Dokumente
            </h3>
        </div>
        <DocumentsHelpText />
        <div
            v-if="documents?.length"
            class="flex flex-col gap-6 mb-6">
            <div
                v-for="document in documents"
                :key="'document-' + document.id"
                class="flex justify-between">
                <div class="w-32 me-6">
                    <BasePopover>
                        <template #trigger>
                            <div
                                class="h-32 w-32 rounded"
                                :class="{'bg-primary':!document.mime_type.startsWith('image/')}">
                                <img
                                    v-if="document.mime_type.startsWith('image/')"
                                    :src="document.url"
                                    :alt="document.file_name"
                                    class="w-full h-full object-cover">

                                <Icon
                                    v-else
                                    :name="fileIcons.getIcon(document)"
                                    class="w-full h-full text-white dark:text-gray-800" />
                            </div>
                        </template>
                        <small>{{ document.file_name }}</small>
                    </BasePopover>
                    <div class="w-32 truncate">
                        <small>{{ document.file_name }}</small>
                    </div>
                </div>
                <div class="flex-grow flex flex-col gap-6">
                    <BaseSwitch
                        v-model="document.visible_for_organisation"
                        disabled
                        :name="'Sichtbar für mich'"
                        :label="'Sichtbar für mich'" />
                    <BaseSwitch
                        v-model="document.visible_for_umbrella_organisation"
                        :disabled="disabled"
                        :name="'Sichtbar für Zuschussbearbeitende'"
                        :label="'Sichtbar für Zuschussbearbeitende'" />
                    <BaseSwitch
                        v-model="document.visible_for_regional_council"
                        :disabled="disabled"
                        :name="'Sichtbar für Zuschussgebende'"
                        :label="'Sichtbar für Zuschussgebende'" />
                </div>
                <div class="flex justify-end items-start">
                    <BaseButton
                        v-if="!disabled"
                        type="danger"
                        icon="heroicons:trash"
                        @click="startDeleteProcess(document)">
                        {{ $t('formProcesses.documents.delete') }}
                    </BaseButton>
                </div>
            </div>
        </div>
        <BaseButton
            v-if="!disabled"
            no-shadow
            type="secondary"
            icon="heroicons:plus"
            @click.prevent="showEditDocumentModal = true">
            {{ $t('add') }}
        </BaseButton>

        <BaseModal
            v-model="showEditDocumentModal"
            title="Dokumente"
            size="md">
            <BaseDropzone
                :model-value="documents"
                accept="application/pdf"
                visibility="public"
                :multiple="true"
                folder="form_process/documents"
                @update:model-value="files => setDocuments(files)" />

            <BaseSubmitButtons
                submit-label="Fertig"
                hide-cancel-button
                @submit.prevent="showEditDocumentModal = false" />
        </BaseModal>

        <DeleteModal
            v-model="isDocumentDeleteModalOpen"
            :title="$t('formProcesses.documents.deleteDocument')"
            :text="documentDeleteText"
            @delete="deleteDocument" />
    </div>
</template>

<script setup>
import {fileIcons} from "@/utils/fileIcons";
import DeleteModal from "@/components/global/DeleteModal.vue";
import {useFormProcessStore} from "@/store/formProcess";

const formProcessStore = useFormProcessStore()
const {t: $t} = useI18n()

const props = defineProps({
    documents: {
        type: Array,
        default: () => []
    },
})

const emit = defineEmits(['update:documents'])

const disabled = computed(() => formProcessStore.formProcessState !== 'editable')

const eventData = computed(() => formProcessStore.formProcessData?.process?.event)

const documents = computed({
    get: () => props.documents.filter((document) => !document.delete),
    set: value => emit('update:documents', value)
})

function setDocuments(files) {
    documents.value = files.map(file => {
        return {
            ...file,
            visible_for_organisation: true,
            visible_for_umbrella_organisation: false,
            visible_for_regional_council: false
        }
    })
}

const showEditDocumentModal = ref(false)

const documentToDelete = ref(null)

const isDocumentDeleteModalOpen = computed({
    get: () => !!documentToDelete.value,
    set: (value) => {
        if (!value) {
            documentToDelete.value = null
        }
    }
})

const documentDeleteText = computed(() => {
    return $t('formProcesses.documents.deleteDocumentText')
})

function startDeleteProcess(documentData) {
    documentToDelete.value = documentData
}

function deleteDocument() {
    const documentId = documentToDelete.value.id ?? null

    if (documentId) {
        if (documentId.toString().startsWith('rand')) {
            documents.value = documents.value.filter((document) => document.id !== documentId)
        } else {
            const findDocument = documents.value.find((document) => document.id === documentId)
            findDocument.delete = true
        }
    }
}

</script>

<style scoped>

</style>