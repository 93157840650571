<template>
    <div>
        <div class="border-b border-gray-200 p-4 sm:p-6 flex">
            <div class="flex-grow mb-3">
                <h2 class="font-bold">
                    {{ formProcessData.form?.fundingProgram?.name }}
                </h2>
                <div class="text-sm text-gray-600">
                    {{ formProcessData.form?.name }}
                </div>
            </div>
            <div>
                <img
                    src="/bdkj-logo.png"
                    class="max-w-24 max-h-24">
            </div>
        </div>
        <div class="border-b border-gray-200">
            <div class="sm:grid sm:grid-cols-3 divide-x max-sm:divide-y">
                <div class="p-4 sm:p-6 text-sm">
                    über
                    <br>{{ formProcessData.organisation?.parentOrganisation?.name }}
                    <br>{{ formProcessData.organisation?.parentOrganisation?.street }} {{ formProcessData.organisation?.parentOrganisation?.house_number }}
                    <br>{{ formProcessData.organisation?.parentOrganisation?.zipcode }} {{ formProcessData.organisation?.parentOrganisation?.city }}
                </div>
                <div class="p-4 sm:p-6 text-sm">
                    {{ formProcessData.organisation?.name }}<br>
                    {{ formProcessData.organisation?.street }} {{ formProcessData.organisation?.house_number }}<br>
                    {{ formProcessData.organisation?.zipcode }} {{ formProcessData.organisation?.city }}
                </div>
                <div>
                    <h2 class="p-4 sm:p-6 font-bold">
                        V{{ formProcessData.process?.year_id?.toString().substring(2) }}-{{ formProcessData.process?.organisation_id }}-{{ formProcessData.process?.serial_number }}
                    </h2>
                    <hr>
                    <BankAccountSelect />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useFormProcessStore} from "@/store/formProcess";

const formProcessStore = useFormProcessStore()

const formProcessData = formProcessStore.formProcessData

const props = defineProps({
    
})
</script>