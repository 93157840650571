<template>
    <div>
        <div
            v-if="formProcessStore.formProcessData.form.identifier === 'KJP_KT'"
            class="mb-6">
            <div v-if="formProcessStore.formProcessData.data.event_type === 'KT'">
                Gesamtbericht über die Teilnahme am Verlauf des Klosterlebens, oder eine Bescheinigung des Klosters
                über die Teilnahme am Klosterleben.
            </div>
            <div v-else-if="formProcessStore.formProcessData.data.event_type === 'TZ'">
                Kopie des Kostenbeleges aus Taizé für Unterkunft und Verpflegung.
            </div>
        </div>
    </div>
</template>

<script setup>
import { useFormProcessStore } from "@/store/formProcess";

const formProcessStore = useFormProcessStore()
</script>

<style scoped>

</style>