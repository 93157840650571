<template>
    <div class=" overflow-hidden">
        <div>
            <div
                class="pt-10"
                :class="{'pb-10' : eventPersonStore.allEventPersons.length === 0}">
                <div class="px-4">
                    <div class="sm:flex sm:items-center">
                        <div class="sm:flex-auto">
                            <h1 class="text-base font-semibold leading-6 text-gray-900">
                                Personen
                            </h1>
                        </div>
                        <div class="mt-4 sm:ml-16 sm:mt-0 flex sm:flex-none">
                            <BaseDropdown class="mr-2">
                                <template #trigger>
                                    <BaseButton
                                        type="secondary"
                                        :disabled="formProcessStore.formProcessState !== 'editable'">
                                        Import
                                        <Icon
                                            name="heroicons:chevron-down"
                                            class="-mr-1 h-5 w-5 text-gray-400" />
                                    </BaseButton>
                                </template>
                                <template>
                                    <div class="py-1">
                                        <MenuItem v-slot="{ active }">
                                            <div
                                                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                Excel
                                            </div>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }">
                                            <div
                                                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                CSV
                                            </div>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }">
                                            <div
                                                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                amosWEB
                                            </div>
                                        </MenuItem>
                                    </div>
                                </template>
                            </BaseDropdown>

                            <BaseDropdown class="mr-2">
                                <template #trigger>
                                    <BaseButton type="secondary">
                                        Export
                                        <Icon
                                            name="heroicons:chevron-down"
                                            class="-mr-1 h-5 w-5 text-gray-400" />
                                    </BaseButton>
                                </template>
                                <template>
                                    <div class="py-1">
                                        <MenuItem v-slot="{ active }">
                                            <div
                                                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                Excel
                                            </div>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }">
                                            <div
                                                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                CSV
                                            </div>
                                        </MenuItem>
                                    </div>
                                </template>
                            </BaseDropdown>

                            <BaseButton
                                type="primary"
                                :disabled="formProcessStore.formProcessState !== 'editable'"
                                @click="addNewEventPerson">
                                {{ $t('formProcesses.eventPersons.addNewEventPerson') }}
                            </BaseButton>
                        </div>
                    </div>
                    <div class="mt-8 flow-root">
                        <div class="-mt-2 -mx-4">
                            <div
                                class="inline-block min-w-full align-middle overflow-y-auto"
                                :class="tableMaxHeightClass">
                                <div ref="listing">
                                    <BaseListing
                                        class="w-full"
                                        :columns="eventPersonsColumns"
                                        :style="'default'"
                                        :sticky-header="true"
                                        :data="eventPersonStore.allEventPersons">
                                        <template #header_assigned>
                                            <th
                                                class="py-3.5 w-[60px] pl-3 pr-3 w-8 text-right text-sm font-semibold text-gray-900 dark:text-gray-50"
                                                :class="{'sticky top-0 bg-white border-b border-gray-300 bg-opacity-75 backdrop-blur backdrop-filter':true}">
                                                <div class="pl-1 sm:pl-3 lg:pl-5">
                                                    <BaseCheckbox
                                                        v-model="assignAllEventPersons"
                                                        :disabled="formProcessStore.formProcessState !== 'editable'"
                                                        name="assignAllEventPersons" />
                                                </div>
                                            </th>
                                        </template>
                                        <template #header_info>
                                            <th
                                                class="py-3.5 pr-3 w-12 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                                                :class="{'sticky top-0 bg-white border-b border-gray-300 bg-opacity-75 backdrop-blur backdrop-filter':true}">
                                                <BaseButton
                                                    :disabled="formProcessStore.formProcessState !== 'editable'"
                                                    type="secondary"
                                                    class="py-[1px] px-[4px] mb-1"
                                                    size="xs">
                                                    auto
                                                </BaseButton>
                                            </th>
                                        </template>
                                        <template #header_birthday>
                                            <th
                                                class="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                                                :class="{'sticky top-0 bg-white border-b border-gray-300 bg-opacity-75 backdrop-blur backdrop-filter':true}">
                                                <div>Geburtstag</div>
                                                Alter
                                                <BasePopover
                                                    size="sm"
                                                    position="left"
                                                    popover-wrapper-classes="inline">
                                                    <template #trigger>
                                                        <div class="inline">
                                                            <Icon
                                                                name="heroicons:question-mark-circle"
                                                                class="h-5 w-5 text-primary-500 hover:bg-primary-500 hover:text-white hover:rounded-full" />
                                                        </div>
                                                    </template>
                                                    <template #default>
                                                        <span class="text-neutral-500 dark:text-gray-300 mt-2">
                                                            Alter dieses Jahr / Alter <br>
                                                            zu Beginn der Veranstaltung
                                                        </span>
                                                    </template>
                                                </BasePopover>
                                            </th>
                                        </template>

                                        <template #assigned="row">
                                            <div class="pl-1 sm:pl-3 lg:pl-5">
                                                <BaseCheckbox
                                                    :model-value="formProcessStore.formProcessData.eventPersons.includes(row.id)"
                                                    :name="'assignedEventPerson' + row.id"
                                                    :disabled="formProcessStore.formProcessState !== 'editable'"
                                                    @update:model-value="toggleEventPerson($event, row)" />
                                            </div>
                                        </template>
                                        <template #person="row">
                                            <strong>{{ row.firstname }} {{ row.lastname }}</strong> ({{ row.gender === 'male' ? 'm' : (row.gender === 'female' ? 'w' : (row.gender === 'diverse' ? 'd' : 'k.A.')) }})
                                            <div>{{ row.zipcode }} {{ row.city }}</div>
                                        </template>
                                        <template #birthday="row">
                                            <div v-if="row.birthday">
                                                {{ $d(dayjs(row.birthday).toDate(), 'dateShort') }}<br>
                                                Alter: {{ dayjs().diff(dayjs(row.birthday), 'year') }} / {{ dayjs(formProcessStore.formProcessData?.process?.event?.start_date).diff(dayjs(row.birthday), 'year') }}
                                            </div>
                                        </template>
                                        <template #function="row">
                                            {{ $t('formProcesses.eventPersons.functions.' + row.function + 'Short') }}
                                        </template>
                                        <template #participation="row">
                                            <span v-if="row.participation_begin">
                                                {{ $d(dayjs(row.participation_begin).toDate(), 'dateShort') }}
                                            </span>
                                            <span v-if="row.participation_begin && row.participation_end">
                                                -
                                            </span>
                                            <span v-if="row.participation_end">
                                                {{ $d(dayjs(row.participation_end).toDate(), 'dateShort') }}
                                            </span>
                                        </template>
                                        <template #actions="row">
                                            <div
                                                v-if="formProcessStore.formProcessState === 'editable'"
                                                class="flex justify-end items-center gap-x-3 pr-1 sm:pr-3 lg:pr-5">
                                                <a
                                                    href="#"
                                                    class="text-primary-600 hover:text-primary-900"
                                                    @click="startEditing(row)">
                                                    <span>Bearbeiten</span>
                                                </a>

                                                <BaseButton
                                                    type="secondary"
                                                    @click.prevent="startDeleteProcess(row)">
                                                    <LoadingSpinner v-if="eventPersonStore.loadingDelete && eventPersonStore.eventPersonToDelete?.id === row.id" />
                                                    <Icon
                                                        v-else
                                                        name="heroicons:trash-20-solid" />
                                                </BaseButton>
                                            </div>
                                        </template>
                                    </BaseListing>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="toggleAvailable"
            class="border-t border-gray-200 py-2">
            <div class="flex justify-center">
                <div
                    v-if="!collapseTable"
                    class="cursor-pointer text-gray-400 text-sm"
                    @click="collapseTable = !collapseTable">
                    <Icon
                        name="heroicons:chevron-down"
                        class="h-4 w-4 transition-transform duration-150" />
                    {{ $t('showMore') }}
                </div>
                <div
                    v-else
                    class="cursor-pointer text-gray-400 text-sm"
                    @click="collapseTable = !collapseTable">
                    <Icon
                        name="heroicons:chevron-up"
                        class="h-4 w-4 transition-transform duration-150" />
                    {{ $t('showLess') }}
                </div>
            </div>
        </div>
        <BaseSlideOver
            v-model="showEditEventPerson"
            :title="(eventPersonStore.eventPersonToEdit?.id && String(eventPersonStore.eventPersonToEdit.id) !== 'newEventPerson') ? $t('formProcesses.eventPersons.editEventPerson') : $t('formProcesses.eventPersons.addNewEventPerson')">
            <div @keydown.enter="$event ? $event.preventDefault() : null">
                <VForm
                    v-slot="validation"
                    @submit="onSubmit()">
                    <div v-if="eventPersonStore.eventPersonToEdit">
                        <EventPersonEdit
                            v-model="eventPersonStore.eventPersonToEdit"
                            :card-required="formProcessStore.formProcessData.form.persons_card === 'required'" />
                    </div>

                    <BaseSubmitButtons
                        :submit-label="$t('formProcesses.eventPersons.saveEventPerson')"
                        :validation="validation"
                        :loading="eventPersonStore.loadingSubmit"
                        @cancel.prevent="showEditEventPerson = false" />
                </VForm>
            </div>
        </BaseSlideOver>

        <DeleteModal
            v-model="isEventPersonDeleteModalOpen"
            :title="$t('formProcesses.eventPersons.deleteEventPerson')"
            :text="$t('formProcesses.eventPersons.deleteEventPersonNameText', {name: eventPersonStore.eventPersonToDelete?.firstname + ' ' + eventPersonStore.eventPersonToDelete?.lastname})"
            @delete="deleteEventPerson" />
    </div>
</template>

<script setup>
import DeleteModal from '@/components/global/DeleteModal.vue'
import {useEventPersonStore} from "@/store/eventPerson";
import {useFormProcessStore} from "@/store/formProcess";
import EventPersonEdit from "@/components/pages/formProcess/EventPersonEdit.vue";

const eventPersonStore = useEventPersonStore()
const formProcessStore = useFormProcessStore()
const {t: $t} = useI18n()
const dayjs = useDayjs()

const collapseTable = ref(false)
const listing = ref(null)
const toggleAvailable = ref(false)

const eventPersonsColumns = [
    {
        "name": "assigned",
        "mobile": true,
        "label": "",
    }, {
        "name": "info",
        "mobile": true,
        "label": "",
    }, {
        "name": "person",
        "mobile": true,
        "label": "Person",
    }, {
        "name": "birthday",
        "mobile": true,
        "label": "Geburtstag, Alter"
    }, {
        "name": "function",
        "mobile": true,
        "label": "Funktion"
    }, {
        "name": "participation",
        "mobile": true,
        "label": "Teilnahme"
    }, {
        "name": "actions",
        "mobile": true,
        "label": "",
    }
]
const assignAllEventPersons = ref(false)

const tableMaxHeightClass = computed(() => {
    return (!collapseTable.value) ? 'max-h-[200px]' : ''
})

const isEventPersonDeleteModalOpen = computed({
    get: () => !!eventPersonStore.eventPersonToDelete,
    set: (value) => {
        if (!value) {
            eventPersonStore.eventPersonToDelete = null
        }
    },
})

const showEditEventPerson = computed({
    get: () => !!eventPersonStore.eventPersonToEdit,
    set: (value) => {
        if (!value) {
            eventPersonStore.eventPersonToEdit = null
        }
    },
})

watch(() => assignAllEventPersons.value, () => {
    formProcessStore.formProcessData.eventPersons = assignAllEventPersons.value ? eventPersons.value.map((eventPerson) => eventPerson.id) : []
})

function toggleEventPerson(value, eventPerson) {
    if (value) {
        if (!formProcessStore.formProcessData.eventPersons.includes(eventPerson.id)) {
            formProcessStore.formProcessData.eventPersons.push(eventPerson.id)
        }
    } else {
        if (formProcessStore.formProcessData.eventPersons.includes(eventPerson.id)) {
            formProcessStore.formProcessData.eventPersons.splice(formProcessStore.formProcessData.eventPersons.indexOf(eventPerson.id), 1)
        }
    }
}

function startDeleteProcess(eventPersonData) {
    eventPersonStore.eventPersonToDelete = eventPersonData
}

function startEditing(eventPersonData) {
    eventPersonStore.eventPersonToEdit = eventPersonData
}

function addNewEventPerson() {
    eventPersonStore.eventPersonToEdit = {
        id: 'newEventPerson',
        function: 'participant',
        gender: '',
        firstname: '',
        lastname: '',
        birthday: '',
        street: '',
        house_number: '',
        zipcode: '',
        city: '',
        country: 'DE',
        participation_begin: formProcessStore.formProcessData.process.event.start_date,
        participation_end: formProcessStore.formProcessData.process.event.end_date,
        participation_duration: formProcessStore.formProcessData.process.event.duration,
    }
}

async function deleteEventPerson() {
    if (!eventPersonStore.eventPersonToDelete) return

    if (formProcessStore.formProcessData.eventPersons.includes(eventPersonStore.eventPersonToDelete.id)) {
        formProcessStore.formProcessData.eventPersons.splice(formProcessStore.formProcessData.eventPersons.indexOf(eventPersonStore.eventPersonToDelete.id), 1)
    }

    await eventPersonStore.deleteEventPersonData(formProcessStore.formProcessData?.process?.event?.id)

    isEventPersonDeleteModalOpen.value = false
}

async function onSubmit(values, actions) {
    if (!eventPersonStore.eventPersonToEdit) return

    if (eventPersonStore.eventPersonToEdit.id === 'newEventPerson') {
        delete eventPersonStore.eventPersonToEdit.id

        await eventPersonStore.storeEventPersonData(formProcessStore.formProcessData?.process?.event?.id)

        showEditEventPerson.value = false
    } else {
        await eventPersonStore.updateEventPersonData(formProcessStore.formProcessData?.process?.event?.id)

        showEditEventPerson.value = false
    }
}

watch(() => eventPersonStore.allEventPersons.length, () => {
    formProcessStore.formProcessData.process.event.eventPersons = eventPersonStore.allEventPersons
    checkListingHeight()
})

async function checkListingHeight() {
    await nextTick()

    toggleAvailable.value = listing.value?.scrollHeight >= 200;
}

onMounted(() => {
    eventPersonStore.fetchAllEventPersons(formProcessStore.formProcessData?.process?.event?.id)
})
</script>

<style scoped></style>
