<template>
    <div
        v-if="eventPersonsData"
        class="mt-10">
        <EventPersonsTable />
    </div>
</template>

<script setup>
import { useFormProcessStore } from "@/store/formProcess";

const formProcessStore = useFormProcessStore()

const cardOpenAtStart = ref(true)

const eventPersonsData = computed(() => {
    if (formProcessStore.formProcessData.process.event.eventPersons.length > 0 && useRoute().hash !== '#eventPersons') {
        cardOpenAtStart.value = false
    }
    return formProcessStore.formProcessData.process.event.eventPersons
})

</script>

<style scoped>

</style>