<template>
    <div>
        <FormHeader />

        <FormGrid>
            <FormField
                field-identifier="event_type"
                label-size="2"
                :form-fields="formFields"
                display="block">
                <template #customInputField="{canEditData}">
                    <BaseRadio
                        v-model="formData.event_type"
                        name="event_type"
                        class="sm:col-span-4"
                        hide-error-messages
                        :disabled="!canEditData"
                        :options="options" />
                </template>
            </FormField>

            <FormCaption id="eventData">
                1. Angaben zur Veranstaltung
            </FormCaption>

            <FormField
                label-size="3"
                input-size="3"
                field-identifier="contact_person_name"
                :form-fields="formFields" />
            <FormField
                label-size="3"
                input-size="3"
                field-identifier="contact_person_mail"
                :form-fields="formFields" />
            <FormField
                label-size="3"
                input-size="3"
                field-identifier="contact_person_phone"
                :form-fields="formFields" />

            <Event
                id="event"
                :label-name="formFields.event_name.label"
                input-name-name="Bezeichnung der Veranstaltung"
                label-location="1.3 PLZ und Ort der Veranstaltung"
                input-location-name="PLZ und Ort der Veranstaltung"
                label-date="1.4 Zeitraum der Veranstaltung"
                input-date-name="Zeitraum der Veranstaltung" />

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="religious_work_hours"
                :form-fields="formFields"
                :tool-tip="true"
                description="2,5 h = 1 Arbeitseinheit" />

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="work_units"
                :form-fields="formFields"
                :tool-tip="true"
                :description="(formData.event_type) ? 'max. ' + formData.event_type + ' AE' : 'max. 12 bzw. 6 AE'" />

            <FormCaption id="participantsData">
                2. Angaben zu den Teilnehmenden
            </FormCaption>

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="eligible_persons_count"
                :form-fields="formFields"
                :person-data-suggestion="eligiblePersonsCountSuggestion"
                @adopt-person-data-suggestion="formData.eligible_persons_count = eligiblePersonsCountSuggestion" />

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="persons_work_units_total"
                :form-fields="formFields" />


            <FormField
                label-size="4"
                input-size="2"
                field-identifier="not_eligible_work_units"
                :form-fields="formFields"
                :tool-tip="true"
                description="Z.B. abzüglich Personen, die an bestimmten Arbeitseinheiten nicht teilgenommen haben." />

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="work_units_total"
                :form-fields="formFields" />

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="subsidy_rate"
                :form-fields="formFields" />

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="max_subsidy_amount"
                :form-fields="formFields" />

            <FormCaption id="costsData">
                3. Gesamtkosten
            </FormCaption>

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="provable_total_costs"
                :form-fields="formFields"
                type="number" />

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="participation_fees_own_resources"
                :form-fields="formFields"
                type="number"
                :tool-tip="true"
                description="TN-Beiträge und Eigenmittel zusammen müssen mindestens 25 % betragen. Entsteht
                                durch Höhe max. Zuschussbetrag + Teilnahmebeiträge/Eigenmittel ein Defizit,
                                muss der Betrag Teilnahmebeiträge/Eigenmittel entsprechend erhöht werden." />

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="sjr_kjr_subsidy"
                :form-fields="formFields"
                type="number" />

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="other_income"
                :form-fields="formFields"
                type="number" />


            <FormField
                label-size="4"
                input-size="2"
                field-identifier="requested_subsidy"
                :form-fields="formFields"
                type="number"
                :tool-tip="true"
                :description="((0.75*formData.provable_total_costs) < formData.max_subsidy_amount) ? 'Darf max. 75 % von 3.1 sein.' : 'Darf 2.3 nicht überschreiten'" />

            <FormField
                label-size="4"
                input-size="2"
                field-identifier="results_in_total"
                :form-fields="formFields"
                type="number" />


            <Documents
                id="documents"
                :documents="formProcessStore.formProcessData.documents"
                @update:documents="files => formProcessStore.formProcessData.documents = files" />
        </FormGrid>
    </div>
</template>

<script setup>
import FormHeader from "@/components/global/Forms/FormHeader.vue";
import FormGrid from "@/components/global/Forms/FormGrid.vue";
import FormField from "@/components/global/Forms/FormField.vue";
import {useFormProcessStore} from "@/store/formProcess";

const formProcessStore = useFormProcessStore()
const dayjs = useDayjs()

const formData = computed(() => formProcessStore.formProcessData.data)
const formProcessData = computed(() => formProcessStore.formProcessData)

const formFields = {
    event_type: {
        fieldIdentifier: 'event_type',
        label: 'Veranstaltungstyp',
        placeholder: '',
    },
    contact_person_name: {
        fieldIdentifier: 'contact_person_name',
        label: '1.1.1 Ansprechperson für Rückfragen',
        placeholder: 'Name',
    },
    contact_person_mail: {
        fieldIdentifier: 'contact_person_mail',
        label: '1.1.2 Ansprechperson E-Mail',
        placeholder: 'E-Mailadresse',
    },
    contact_person_phone: {
        fieldIdentifier: 'contact_person_phone',
        label: '1.1.3 Ansprechperson Telefon',
        placeholder: 'Telefon',
    },
    event_name: {
        fieldIdentifier: 'event_name',
        label: '1.2 Bezeichnung der Veranstaltung',
        placeholder: 'Bezeichnung der Veranstaltung',
    },
    event_zipcode_city: {
        fieldIdentifier: 'event_zipcode_city',
        label: '1.3 PLZ und Ort der Veranstaltung',
        placeholder: 'PLZ und Ort der Veranstaltung',
    },
    event_start_date: {
        fieldIdentifier: 'event_start_date',
        label: '1.4.1 Beginn der Veranstaltung',
    },
    event_end_date: {
        fieldIdentifier: 'event_end_date',
        label: '1.4.2 Ende der Veranstaltung',
    },
    religious_work_hours: {
        fieldIdentifier: 'religious_work_hours',
        label: '1.4.3 Stunden religiöser Arbeit insgesamt',
        placeholder: 'Stunden insgesamt',
    },
    work_units: {
        fieldIdentifier: 'work_units',
        label: '1.4.4 Arbeitseinheiten',
        placeholder: 'Arbeitseinheiten',
        disabled: true,
    },
    eligible_persons_count: {
        fieldIdentifier: 'eligible_persons_count',
        label: '2.1 Zahl der zuwendungsfähigen Teilnehmenden (mind. 5, zw. 10 und einschl. 26 Jahren)',
        placeholder: 'Anzahl der Teilnehmenden',
    },
    persons_work_units_total: {
        fieldIdentifier: 'persons_work_units_total',
        label: '2.2 Arbeitseinheiten aller Teilnehmenden',
        placeholder: 'Arbeitseinheiten aller Teilnehmenden',
        disabled: true,
    },
    not_eligible_work_units: {
        fieldIdentifier: 'not_eligible_work_units',
        label: '2.2.1 Abzgl. nicht anrechenbare Arbeitseinheiten',
        placeholder: 'Abzgl. nicht anrechenbare Arbeitseinheiten',
    },
    work_units_total: {
        fieldIdentifier: 'work_units_total',
        label: '2.2.2 Gesamt-Arbeitseinheiten',
        placeholder: 'Gesamt-Arbeitseinheiten',
        disabled: true,
    },
    subsidy_rate: {
        fieldIdentifier: 'subsidy_rate',
        label: 'Zuschusssatz pro Arbeitseinheit',
        placeholder: 'Zuschusssatz pro Arbeitseinheit',
        disabled: true,
    },
    max_subsidy_amount: {
        fieldIdentifier: 'max_subsidy_amount',
        label: '2.3 Maximale Zuschusshöhe',
        placeholder: 'Maximale Zuschusshöhe',
        disabled: true,
    },
    provable_total_costs: {
        fieldIdentifier: 'provable_total_costs',
        label: '3.1 belegbare Gesamtkosten (keine Investitionskosten!)',
        placeholder: 'Belegbare Gesamtkosten (keine Investitionskosten!)',
    },
    participation_fees_own_resources: {
        fieldIdentifier: 'participation_fees_own_resources',
        label: '3.2 Teilnahmebeiträge/Eigenmittel (zusammen mind. 25 %)',
        placeholder: 'Teilnahmebeiträge/Eigenmittel (zusammen mind. 25 %)',
    },
    sjr_kjr_subsidy: {
        fieldIdentifier: 'sjr_kjr_subsidy',
        label: '3.3 Zuschüsse von Stadt-/Kreisjugendring',
        placeholder: 'Zuschüsse von Stadt-/Kreisjugendring',
    },
    other_income: {
        fieldIdentifier: 'other_income',
        label: '3.4 Sonstige Einnahmen (z.B. Spenden)',
        placeholder: 'Sonstige Einnahmen (z.B. Spenden)',
    },
    requested_subsidy: {
        fieldIdentifier: 'requested_subsidy',
        label: '3.5 Erbetener Zuschuss',
        placeholder: 'Erbetener Zuschuss',
    },
    results_in_total: {
        fieldIdentifier: 'results_in_total',
        label: '3.5 Ergibt wieder (Muss gleich sein wie 3.1)',
        placeholder: 'Ergibt wieder (Muss gleich sein wie 3.1)',
        disabled: true,
    },
}

const options = [
    {
        label: 'auf Gewährung eines Zuschusses für durchgängig religiöse Maßnahmen (max. 12 Arbeitseinheiten)',
        value: '12'
    },
    {
        label: 'auf Gewährung eines Zuschusses für Freizeiten mit religiösen Arbeitseinheiten (max. 6 Arbeitseinheiten)',
        value: '6'
    }]

const eligiblePersonsCountSuggestion = computed(() => {
    const eligiblePersons = formProcessData.value.process.event.eventPersons.filter(person => {
        if (formProcessData.value.eventPersons.includes(person.id) &&
            person.function === 'participant' &&
            person.birthday &&
            dayjs(formProcessData.value.data.event_end_date).diff(dayjs(person.birthday), 'year') >= 10 &&
            dayjs(formProcessData.value.data.event_start_date).diff(dayjs(person.birthday), 'year') <= 26) {
            return person
        }
    })
    return eligiblePersons.length
})

watchEffect(() => {
    formData.value.work_units = Math.floor(parseFloat(formData.value.religious_work_hours) / 2.5)
})

watchEffect(() => {
    formData.value.persons_work_units_total = parseInt(formData.value.eligible_persons_count) * parseInt(formData.value.work_units)
})

watchEffect(() => {
    formData.value.work_units_total = parseInt(formData.value.persons_work_units_total) - parseInt(formData.value.not_eligible_work_units)
})

watchEffect(() => {
    formData.value.max_subsidy_amount = parseInt(formData.value.work_units_total) * parseInt(formData.value.subsidy_rate)
})

watchEffect(() => {
    const resultsInTotal = 0 + (formData.value.participation_fees_own_resources || 0)
        + (formData.value.sjr_kjr_subsidy || 0)
        + (formData.value.other_income || 0)
        + (formData.value.requested_subsidy || 0)
    formData.value.results_in_total = parseFloat(resultsInTotal.toFixed(2))
})

</script>

<style scoped>

</style>